@import url("./nav.css");
@import url("./sidebar.css");
@import url("./main.css");
@import url("./footer.css");

body.grid {
	grid-template-areas: "nav nav nav"
	". main sidebar"
	"footer footer footer";
	row-gap: 1.3em;
	grid-template-rows: var(--nav-height, 4rem) minmax(300px, auto) minmax(300px, auto);
	grid-template-columns: 1rem 1fr minmax(var(--sidebar-min-width, 250px), var(--sidebar-max-width, 400px));
}

#nav {
	grid-area: nav;
}

#main {
	grid-area: main;
}

#sidebar {
	grid-area: sidebar;
}

#footer {
	grid-area: footer;
}

weather-current {
	margin: 0;
}

#sidebar ad-block {
	width: 100%;
}

@media (max-width: 800px) {
	body.grid {
		grid-template-areas: "nav" "main" "sidebar" "footer";
		grid-template-columns: 100%;
	}
}

.content {
	margin: 1.2em;
	padding: 0.4em 1.4em;
}

.overflow-x-auto {
	overflow-x: auto;
}

fieldset {
	padding: 2.3em;
}

legend {
	font-size: 1.4em;
	font-weight: bold;
}

dialog {
	max-height: var(--dialog-max-height, 90vh);
	max-width: var(--dialog-max-width, 90vw);
	overflow: auto;
}

.no-margins {
	margin: 0;
}

.icon-3vw {
	height: 3vw;
	width: 3vw;
}

.icon-3vmax {
	height: 3vmax;
	width: 3vmax;
}

button.btn[is="share-button"] {
	bottom: 1rem;
	right: 1rem;
	padding: 0.8rem;
	font-size: 1.5rem;
}

@media (max-width: 800px) {
	.mobile-hidden {
		display: none;
	}
}

@media (min-width: 800px) {
	body.grid {
		grid-column-gap: 1.5rem;
	}
}

[data-flex-grow="0"] {
	flex-grow: 0;
}

[data-flex-grow="1"] {
	flex-grow: 1;
}

[data-flex-grow="2"] {
	flex-grow: 2;
}

[data-flex-grow="3"] {
	flex-grow: 3;
}

[data-flex-grow="4"] {
	flex-grow: 4;
}

[data-flex-shrink="0"] {
	flex-shrink: 0;
}

[data-flex-shrink="1"] {
	flex-shrink: 1;
}

[data-flex-shrink="2"] {
	flex-shrink: 2;
}

[data-flex-shrink="3"] {
	flex-shrink: 3;
}

[data-flex-shrink="4"] {
	flex-shrink: 4;
}
